import React, { useEffect, useState } from "react"
import './hero-slider.css'

const slides = [
  {
    id: 1,
    title: 'We unravel <br/>transformations',
    description: 'Discover custom digital tools <br/>for evolutionary business growth.'
  },
  {
    id: 2,
    title: 'We build <br/>lasting experiences',
    description: 'Create an engaging web journey <br/>for your clients.'
  },
  {
    id: 3,
    title: 'We launch <br/>quick and neat',
    description: 'Best way to validate your idea is to take it to the market. <br/>We do it fast, <i>really fast</i>.'
  },
  {
    id: 4,
    title: 'We conquer their <br/>phone, pc & mind',
    description: 'Don’t let platforms constrain your reach. <br/>Launch for every device'
  }
]
const HeroSlider = () => {
  const [state, setState] = useState({ activeSlide: 0 })

  const play = () => {
    setTimeout(() => {
      const activeSlide = state.activeSlide === (slides.length - 1) ? 0 : (state.activeSlide + 1);
      return setState({ ...state, activeSlide });
    }, 5000);
  }

  useEffect(() => play());
  return (
    <div className="slides">
      <div className="pager">
        <div className={`bullet ${(state.activeSlide === 0) ? "active" : ""}`}></div>
        <div className={`bullet ${(state.activeSlide === 1) ? "active" : ""}`}></div>
        <div className={`bullet ${(state.activeSlide === 2) ? "active" : ""}`}></div>
        <div className={`bullet ${(state.activeSlide === 3) ? "active" : ""}`}></div>
      </div>
      {slides.map((slide, index) => ( 
        <div className={`slide ${(state.activeSlide === index) ? "active" : ""}`} key={slide.id}>
          <h3 className="tagline" dangerouslySetInnerHTML={{ __html: slide.title }}></h3>
          <p className="desc" dangerouslySetInnerHTML={{ __html: slide.description }}></p>
        </div>
      ))}
    </div>
  )
}

export default HeroSlider
