import React, { useState } from "react"
import './email-box.css'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const EmailBox = () => {
  const [state, setState] = useState({})

  const handleChange = (e) => {
    const button = document.querySelector('.send-btn');
    if (e.target.value && validateEmail(e.target.value)) {
      button.removeAttribute('disabled');
      return setState({ ...state, [e.target.name]: e.target.value });
    }
    button.setAttribute('disabled', true);
  }

  const onSuccessfulSubmit = () => {
    setState({ ...state, isSubmitted: true })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => onSuccessfulSubmit())
      .catch((error) => alert(error))
  }

  return (
    <>
      <form 
        className={`email-box ${state.isSubmitted ? "hidden" : ""}`}
        method="post"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <input type="email" placeholder="Enter your email address" name="email" onChange={handleChange}/>
        <button className="send-btn" disabled="{true}" type="submit">Send</button>
      </form>
      <p className={`success desc ${state.isSubmitted ? "active" : "hidden"}`}>Thank you for reaching out us. We will get back to you in next 24 hours.</p>
    </>
  )
}

export default EmailBox