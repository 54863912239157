import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo/seo"
import { StaticImage } from 'gatsby-plugin-image'

import EmailBox from "../components/email-box/email-box"
import HeroSlider from "../components/hero-slider/hero-slider"

const IndexPage = () => (
  <>
  <SEO title="heisentech | Designers of elegant websites & intuitive apps" description="Web Design · Mobile Application Development · Cloud Solutions · iOS Development · Android Development · Business Website · Digital Transformation · MVP"/>
  <section className="section hero">
    <div className="container big">
      <div className="col text">
        <HeroSlider/>
        <div className="btn-wrapper">
          <a href="https://cal.com/nikhil01/15min" className="btn primary" target="_blank">
            Book consultation
            <StaticImage
              src="./../images/icons/bx-right-arrow-alt.svg"
              quality={95}
              width={24}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Arrow icon"
              style={{ marginLeft: '0.5rem' }}
            />
          </a>
        </div>
      </div>
      <div className="col img-wrapper">
        <StaticImage
          src="./../images/hero-img.svg"
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          placeholder="none"
          alt="Website building on rocket"
        />
      </div>
    </div>
  </section>
  <section className="section clients-wrapper">
    <div className="container big">
      <div className="title-wrapper">
        <h4 className="title" title="Our stories">Our stories</h4>
        <Link to="/stories" className="btn primary">
          View all
          <StaticImage
            src="./../images/icons/bx-right-arrow-alt.svg"
            quality={95}
            width={24}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Arrow icon"
            style={{ marginLeft: '0.5rem' }}
          />
        </Link>
      </div>
      <div className="clients">
        <div className="client">
          <div className="logo">
            <StaticImage
              src="./../images/clients/pep-logo.png"
              height={25}
              transformOptions={{ fit: 'contain' }}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="PEP Logo"
            />
          </div>
          <div className="details">
            <h3 className="label">Photography gets it's own community.</h3>
            <div className="btn-wrapper">
              <Link to="/stories#pep" className="link">
              <StaticImage
                src="./../images/icons/bx-right-arrow-alt.svg"
                quality={95}
                width={22}
                transformOptions={{ fit: 'contain' }}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                alt="Arrow icon"
              />
              </Link>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="logo">
            <StaticImage
              src="./../images/clients/cricstrat-logo.png"
              height={40}
              transformOptions={{ fit: 'contain' }}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Cricstrat Logo"
            />
          </div>
          <div className="details">
            <h3 className="label">Our 20-20 match: Fantasy cricket</h3>
            <div className="btn-wrapper">
              <Link to="/stories#cricstrat" className="link">
                <StaticImage
                  src="./../images/icons/bx-right-arrow-alt.svg"
                  quality={22}
                  width={24}
                  transformOptions={{ fit: 'contain' }}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                  alt="Arrow icon"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="client">
          <div className="logo">
            <StaticImage
              src="./../images/clients/shubhvivah-logo.png"
              height={40}
              transformOptions={{ fit: 'contain' }}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Shubhvivah Logo"
            />
          </div>
          <div className="details">
            <h3 className="label">Targeted matrimony apps for all</h3>
            <div className="btn-wrapper">
              <Link to="/stories#shubhvivah" className="link">
                <StaticImage
                  src="./../images/icons/bx-right-arrow-alt.svg"
                  quality={95}
                  width={24}
                  transformOptions={{ fit: 'contain' }}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder="none"
                  alt="Arrow icon"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section solutions">
    <div className="container big">
      <div className="blocks">
        <Link to="/services#ideation-and-mvp" className="block service">
          <div className="img-wrapper">
            <StaticImage
              src="./../images/home-services/ideation.png"
              width={80}
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ideation and MVP"
            />
          </div>
          <div className="name">Ideation and MVP</div>
          <p className="desc small">
            A grand idea doesn't need grand resources at the validation stage, right? You specify the crux of your concept and we will deliver to you a working MVP in record time.
          </p>
          <div className="btn-link">Know more</div>
        </Link>
        <Link to="/services#ux-and-ui" className="block service">
          <div className="img-wrapper">
            <StaticImage
              src="./../images/home-services/UX.png"
              width={80}
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ideation and MVP"
            />
          </div>
          <div className="name">UX and UI Design</div>
          <p className="desc small">
            Artists are many, but you need professionals. We get to the core of your company’s identity and ensure your brand speaks for you. 
          </p>
          <div className="btn-link">Know more</div>
        </Link>
        <div className="block label">
          <div className="title" title="Our services">Our services</div>
          <Link to="/services" className="btn primary">
            View all
            <StaticImage
              src="./../images/icons/bx-right-arrow-alt.svg"
              quality={95}
              width={24}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Arrow icon"
              style={{ marginLeft: '0.5rem' }}
            />
          </Link>
        </div>
      </div>
      <div className="blocks">
        <Link to="/services#web-development" className="block service">
          <div className="img-wrapper">
            <StaticImage
              src="./../images/home-services/web-development.png"
              width={80}
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ideation and MVP"
            />
          </div>
          <div className="name">Web development</div>
          <p className="desc small">
            A good website awe’s the user but a great website engages, captivates and inspires action from the user. It helps drive conversions and sales. A great website is a strategic business investment and we build just that.
          </p>
          <div className="btn-link">Know more</div>
        </Link>
        <Link to="/services#app-development" className="block service">
          <div className="img-wrapper">
            <StaticImage
              src="./../images/home-services/mobile-development.png"
              width={80}
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ideation and MVP"
            />
          </div>
          <div className="name">App Development</div>
          <p className="desc small">
            Want to stay on your prospect’s mind? Pro tipstay on their cell phone. We build fast, responsive, and enthralling apps that run on any platform. So that you can reach prospects beyond the boundaries of operating systems.
          </p>
          <div className="btn-link">Know more</div>
        </Link>
        <Link to="/services#maintenance" className="block service">
          <div className="img-wrapper">
            <StaticImage
              src="./../images/home-services/support.png"
              width={80}
              quality={80}
              formats={["AUTO", "WEBP", "AVIF"]}
              placeholder="none"
              alt="Ideation and MVP"
            />
          </div>
          <div className="name">Maintenance</div>
          <p className="desc small">
            Want to avoid disruption of a deployed project? We provide meticulous attention and care in upgrading and maintaining a project entrusted with us.
          </p>
          <div to="/services#maintenance" className="btn-link">Know more</div>
        </Link>
      </div>
    </div>
  </section>
  <section className="section about-us">
    <div className="container big">
      <div className="text">
        <h4 className="title" title="About us">About us</h4>
        <p className="desc small">
          We provide digital solutions. It means understanding your need and providing customised softwares or applications. There is no one size fits all approach. For example, let's say your
          business needs digital presence. We understand your industry from you. 
        </p>
        <p className="desc small">
          A market study is conducted to understand what is most likely to bring a customer to your website. Content for your
          site is written in such a way that it clicks with your customers as well as optimises your search result ranking. Our UI elements are custom made so that they don't look like every other site.
          We ensure that the user experience on your platform remains distinctly different from your competition.
        </p>
        <Link to="/about-us" className="btn primary">
          Know more
          <StaticImage
            src="./../images/icons/bx-right-arrow-alt.svg"
            quality={95}
            width={24}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Arrow icon"
            style={{ marginLeft: '0.5rem' }}
          />
        </Link>
      </div>
    </div>
  </section>
  <section className="section quote">
    <div className="container big">
      <div className="quote-wrapper">
        <div className="title">
          All great things start with simple conversations
          <br/>
          Drop your email id to begin one.
        </div>
        <EmailBox/>
      </div>
      <div className="img">
        <StaticImage
          src="./../images/mailbox.png"
          quality={95}
          objectFit="contain"
          formats={["AUTO", "WEBP", "AVIF"]}
          placeholder="none"
          alt="Mailbox"
          className="mailbox-img"
        />
      </div>
    </div>
  </section>
  </>
)

export default IndexPage
